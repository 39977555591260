import get from '@invitato/helpers/dist/getLocale';

export default {
  textButton: get('Kisah Cinta Kami', 'Our Love Story'),
  desc: get(
    `Baca dan ikuti perjalanan cinta kami <br />sebagai pasangan di sini:`,
    `Read and follow our love of live <br />journey as a couple here:`,
  ),
  backToHome: get('Kembali ke Undangan', 'Back to Invitation'),
  backToAnnouncement: get('Kembali', 'Back'),
};