import React from 'react';
import { bool } from 'prop-types';
import { Box, Center, Text, Link } from '@chakra-ui/react';
import { TiHeart } from 'react-icons/ti';

import Wave from '@components/Common/Wave';

import { THE_BRIDE, SOUND_BY, URL_WA_INVITATO, SOUND_URL } from '@/constants';
import { TEXT_PRIMARY_LIGHT } from '@/constants/colors';

function CopyRightSection({ withSong, withFlatIcon }) {
  return (
    <Box height="0">
      <Wave style={{ backgroundColor: 'transparent', transform: 'translateY(-38px)' }} />
      <Box p={4} bgColor="theme.bgSecondary" transform="translateY(-40px)">
        <Center>
          <Text color="mainColorTextLight">Created with</Text>
          <TiHeart className="animation-heart" style={{ margin: '0 4px' }} color={TEXT_PRIMARY_LIGHT} />
          <Link href={URL_WA_INVITATO} color="mainColorTextLight" isExternal>
            by Invitato
          </Link>
        </Center>
        <Center>
          <Text color="mainColorTextLight" fontSize="sm">
            {`© ${new Date().getFullYear()} ${THE_BRIDE}. All Rights Reserved`}
          </Text>
        </Center>
        {withSong && (
          <Center>
            <Link href={SOUND_URL} color="white" fontSize="sm" isExternal>
              {`Song by ${SOUND_BY}`}
            </Link>
          </Center>
        )}
        {withFlatIcon && (
          <Center>
            <Link href="https://www.flaticon.com/" color="white" fontSize="sm" isExternal>
              {`Icon by FlatIcon`}
            </Link>
          </Center>
        )}
        <Center m="1" />
      </Box>
    </Box>
  );
}

CopyRightSection.propTypes = {
  withFlatIcon: bool,
  withSong: bool,
};

CopyRightSection.defaultProps = {
  withFlatIcon: true,
  withSong: true,
};

export default React.memo(CopyRightSection);
