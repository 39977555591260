import React from 'react';
import { Box, Text, Divider, Center } from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';
import Image from '@components/Common/LazyImage';
import txtWording from './locales';

import { BG_PATTERN, BG_AYAT } from '@/constants/assets';
import useLang from '@hooks/useLang';

/**
 * function to render ayat section with Ayat content
 * @returns {JSX.Element}
 */
function AyatSection() {
  const lang = useLang();
  return (
    <Box padding="42px 32px" style={{ backgroundImage: `url(${BG_PATTERN})` }}>
      <Box p="24px 16px" bg="mainColor" borderRadius="16px">
        <Box marginBottom="16px">
          <Center>
            <WithAnimation>
              <Image src={BG_AYAT} borderTopRadius="48%" borderBottomRadius="8px" height="300px" />
            </WithAnimation>
          </Center>
        </Box>
        <Center>
          <Divider width="1px" bg="mainColorTextLight" borderBottom="none" height="50px" margin="2px 0 12px" />
        </Center>
        <WithAnimation>
          <Text align="center" fontSize="sm" color="mainColorTextLight" fontStyle="italic">
            {txtWording.textAyat[lang]}
          </Text>
        </WithAnimation>
        <WithAnimation>
          <Text align="center" color="mainColorTextLight" marginTop="16px" fontSize="xs" fontWeight="bold">
            {txtWording.ayatName[lang]}
          </Text>
        </WithAnimation>
      </Box>
    </Box>
  );
}

export default React.memo(AyatSection);