// import Img1 from './images/gallery/1.jpg';
// import Img2 from './images/gallery/1.jpg';
// import Img3 from './images/gallery/1.jpg';
// import Img4 from './images/gallery/1.jpg';
// import Img5 from './images/gallery/1.jpg';
// import Img6 from './images/gallery/1.jpg';
// import Img7 from './images/gallery/1.jpg';
// import Img8 from './images/gallery/1.jpg';

export default [
  {
    // img: Img1,
    title: '2018',
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
  },
  {
    // img: Img2,
    title: '2019',
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
  },
  {
    // img: Img3,
    title: '2020',
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
  },
];
