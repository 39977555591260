import React from 'react';
import { Box, Heading, Center, Text } from '@chakra-ui/react';

import WithAnimation from '@/components/Common/WithAnimation';
import Wave from '@/components/Common/Wave';

import useGuestName from '@/hooks/useGuestName';
import useInvitation from '@/hooks/useInvitation';
import txtWording from './locales';

import { MUSLIM_INVITATION } from '@/constants/features-flag';
import { BG_SECONDARY } from '@/constants/colors';
import { styWelcomeV3 } from './styles';
import useLang from '@hooks/useLang';

/**
 * Function to render Component WelcomeV3
 * @return {JSX.Element}
 */
function WelcomeV3() {
  const guestName = useGuestName();
  const isInvitation = useInvitation();
  const lang = useLang();
  
  return (
    <Box minHeight="100vh" width="100%" backgroundColor={BG_SECONDARY}>
      <Box backgroundColor={BG_SECONDARY}>
        <Box css={styWelcomeV3} />
        <Wave style={{ backgroundColor: 'transparent', transform: 'translateY(-2.8rem)' }} />
        <Box transform="translateY(calc(-3rem - 4px))" padding="16px 16px 0 16px">
          <Center>
            <WithAnimation>
              <Heading textAlign="center" color="mainColorTextLight" marginBottom="2" fontSize="xl">
                {txtWording.textDear[lang]} {' '} {guestName ? guestName : txtWording.guest[lang]},
                <br /> 
              </Heading>
            </WithAnimation>
          </Center>
          {false &&
          <Center>
            <WithAnimation>
              <Heading textAlign="center" color="mainColorTextLight" marginBottom="2" fontSize="xl">
                <span>{guestName ? guestName : txtWording.guest[lang]}</span>
                <br />
              </Heading>
            </WithAnimation>
          </Center>
          }
          <WithAnimation>
            <Text textAlign="center" color="mainColorTextLight" fontSize="sm">
              {MUSLIM_INVITATION &&
                <>
                  Assalamualaikum Warahmatullahi Wabarakatuh.
                  <br />
                </>
              }
              {isInvitation
                ? <>{txtWording.welcomingInvitation[lang]}</>
                : <>{txtWording.welcomingAnnuncement[lang]}</>
              }
            </Text>
          </WithAnimation>
        </Box>
      </Box>
    </Box>
  );
}

export default React.memo(WelcomeV3);
