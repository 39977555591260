import React from 'react';
import { bool, func } from 'prop-types';
import { BiArrowBack } from 'react-icons/bi';

import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  DrawerCloseButton,
  Image
} from '@chakra-ui/react';

import { Timeline, TimelineEvent } from 'react-event-timeline';
import { AiOutlineHeart } from 'react-icons/ai';

// import ImgLogo from '@/assets/icons/bouquet.svg';
import useInvitation from '@/hooks/useInvitation';
import loveStoryData from '@/api/love-story';
import txtWording from './locales';

import { BG_LOVESTORY } from '@/constants/assets';
import { DEFAULT_LANGUAGE as lang } from '@/constants/features-flag';

function LoveStoryContent({ isOpen, onClose }) {
  const invitation = useInvitation();
  const renderIcon = (img) => {
    if (img) {
      return (
        <Image
          src={img}
          boxSize="50px"
          borderRadius="full"
          objectFit="cover"
          objectPosition="center"
        />
      );
    }

    return <AiOutlineHeart color="white" size="18px" />;
  };

  return (
    <Drawer size="full" isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent maxW="500px">
        <DrawerCloseButton color="transparent" />
        <DrawerBody bgImage={`url(${BG_LOVESTORY})`} bgSize="cover" bgPos="center">
          <Box paddingTop="32px">
            <Box bgColor="theme.bgSecondary" borderRadius="16px" padding="16px">
              {/* <Center>
                <Image src={ImgLogo} maxW="150px" />
              </Center> */}
              <Timeline
                lineColor="white"
                lineStyle={{ top: '20px', height: 'calc(100% - 60px)', left: '25px' }}
              >
                {loveStoryData.map((item, index) => {
                  return (
                    <TimelineEvent
                      key={`index-${index}`}
                      title={item.title}
                      titleStyle={{ color: 'white', fontWeight: 'bold', paddingLeft: '20px' }}
                      subtitleStyle={{ color: 'white' }}
                      cardHeaderStyle={{ color: 'white' }}
                      icon={renderIcon(item.img)}
                      iconStyle={{ width: '50px', height: '50px' }}
                      bubbleStyle={{
                        width: '50px',
                        height: '50px',
                        border: 'none',
                        backgroundColor: '#67544e',
                      }}
                      contentStyle={{
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        padding: '0',
                        color: 'white',
                        fontSize: '14px',
                        fontWeight: '400',
                        paddingLeft: '20px',
                        marginTop: '8px',
                      }}
                    >
                      <span dangerouslySetInnerHTML={{ __html: item.description }} />
                    </TimelineEvent>
                  );
                })}
              </Timeline>
            </Box>
          </Box>
          <Center>
            <Button
              leftIcon={<BiArrowBack />}
              size="sm"
              fontWeight="normal"
              margin="16px 0"
              color="mainColorText"
              bgColor="bgPrimary"
              onClick={() => onClose()}
            >
              {invitation
                ? <>{txtWording.backToHome[lang]}</>
                : <>{txtWording.backToAnnouncement[lang]}</>
              }
            </Button>
          </Center>
          <Box height="50px" />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

LoveStoryContent.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
};

export default LoveStoryContent;
