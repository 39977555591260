import React from 'react';
import { bool, func } from 'prop-types';

import {
  Text,
  Box,
  Center,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerHeader,
  DrawerCloseButton,
} from '@chakra-ui/react';

import txtWording from './locales';
import { string } from 'prop-types';
import useInvitation from '@hooks/useInvitation';
import useLang from '@hooks/useLang';

function ModalAddress({ onClose, visible, onCopyText }) {
  const address = `Jalan Johar Baru IV No. 2, Johar Baru, Jakarta Pusat 10560`;
  const receiverName = `Oswald & Lucella`;
  const lang = useLang();
  
  const isInvitation = useInvitation();
  const renderBank = () => {
    return (
      <Box padding="16px 0">
        <Text textAlign="center" marginTop="0" fontSize="md">
          {receiverName} <br />
        </Text>
        <Text textAlign="center" color="black" fontSize="md">
          {address}
        </Text>
        <Center>
          <Button
            size="xs"
            fontWeight="normal"
            colorScheme="blackAlpha"
            bgColor="mainColor"
            marginTop="16px"
            textColor="mainColorTextLight"
            onClick={() => onCopyText(`${receiverName} - ${address}`, txtWording.successCopyAddress[lang])}
          >
            {txtWording.modalCopy[lang]}
          </Button>
        </Center>
      </Box>
    );
  };

  return (
    <Drawer size="full" onClose={onClose} isOpen={visible}>
      <DrawerOverlay />
      <DrawerContent maxWidth="500px">
        <DrawerCloseButton marginTop="12px" />
        <DrawerHeader
          fontFamily="fantasy"
          borderBottomWidth="1px"
          fontSize="2xl"
          fontWeight="normal"
          color="mainColorText"
        >
          {txtWording.sendGift[lang]}
        </DrawerHeader>
        <DrawerBody>
          <Text align="center" fontSize="md" margin="16px 0" color="black">
            {txtWording.modalDescAddress[lang]}
          </Text>
          {renderBank()}
          <Text align="center" fontSize="md" margin="16px 0" color="black">
            {isInvitation ? 
              lang == 'en' ? `We are grateful for your presence at our wedding, but if you would like to honor us 
                            with a gift, please let us know in advance.` 
                          :
                            `Kami berterima kasih atas kehadiran Anda pada acara pernikahan kami, namun apabila Anda ingin mengirim hadiah, mohon beritahu kami sebelumnya.` 
              : 
              lang == 'en' ? `Thank you for letting us know in advance should you want to honor us with a gift.`
                          : `Terima kasih untuk memberitahu kami sebelumnya apabila Anda ingin mengirim hadiah fisik.`
            }
          </Text>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

ModalAddress.propTypes = {
  lang: string,
  visible: bool.isRequired,
  onClose: func.isRequired,
  onCopyText: func.isRequired,
};

ModalAddress.defaultProps = {
  lang: 'id',
};

export default ModalAddress;