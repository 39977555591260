import React from 'react';
import { node, string, oneOfType, object } from 'prop-types';
import { Box, Center, Image, Text } from '@chakra-ui/react';

import { TEXT_PRIMARY_LIGHT } from '@/constants/colors';

function WeddingDetail({ imgSrc, title, titleProps, subTitle, color, Icon }) {
  return (
    <Box m="4" marginBottom="24px">
      <Center>{imgSrc ? <Image src={imgSrc} boxSize="80px" /> : Icon}</Center>
      <Center>
        <Text
          fontSize="xl"
          fontWeight="bold"
          color={TEXT_PRIMARY_LIGHT}
          fontFamily="fantasy"
          {...titleProps}
        >
          {title}
        </Text>
      </Center>
      <Center>
        <Text fontSize="sm" color={color} align="center">
          {subTitle}
        </Text>
      </Center>
    </Box>
  );
}

WeddingDetail.propTypes = {
  Icon: node,
  color: string,
  imgSrc: string,
  title: string.isRequired,
  titleProps: object,
  subTitle: oneOfType([string, object]),
};

WeddingDetail.defaultProps = {
  color: 'theme.bgSecondary',
  imgSrc: '',
  Icon: null,
  titleProps: {},
};

export default React.memo(WeddingDetail);
