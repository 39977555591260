import React from 'react';
import { Box, useDisclosure } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';

import Img from 'gatsby-image';
import ImagesViewer from '@components/Common/ImagesViewer/Lazy';
import WithAnimation from '@components/Common/WithAnimation';

import { BG_PRIMARY, BG_SECONDARY_DARK} from '@/constants/colors';
import { DEFAULT_LANGUAGE as lang } from '@/constants/features-flag';

function CovidSection({...rest}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const data = useStaticQuery(graphql`
    query {
      covid: file(relativePath: { eq: "covid.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      covidEn: file(relativePath: { eq: "covid-en.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  
  return (
    <Box {...rest}>
      <Box p={4} paddingTop="32px" paddingBottom="0" bgColor={BG_PRIMARY}>
        <Box height="100px" />
      </Box>
      <Box padding="16px 32px" bgColor="mainColor" marginBottom="10px">
        <WithAnimation>
          <Box
            boxShadow="lg"
            borderRadius="40px"
            onClick={onOpen}
            backgroundColor="mainColor"
            border={`6px solid ${BG_SECONDARY_DARK}`}
            transform="translateY(-100px)"
            marginBottom="-70px"
          >
            <Img
              fluid={lang === 'en' ? data.covidEn.childImageSharp.fluid : data.covid.childImageSharp.fluid}
              imgStyle={{ borderRadius: '32px' }}
              style={{ backgroundColor: 'transparent' }}
            />
          </Box>
        </WithAnimation>
      </Box>
      {isOpen && (
        <ImagesViewer 
          images={[
            lang === 'en' 
              ? data.covidEn.childImageSharp.fluid.src
              : data.covid.childImageSharp.fluid.src
          ]}onClose={onClose}
        />
      )}
    </Box>
  );
}

export default React.memo(CovidSection);
