import React from 'react';
import { number, string } from 'prop-types';
import { Box, Text } from '@chakra-ui/react';

function CountItem({ number, text }) {
  return (
    <Box p={3}>
      <Box>
        <Text
          textAlign="center"
          fontSize="3xl"
          fontWeight="bold"
          color="mainColorTextLight"
          className="animation-heart"
          fontFamily="fantasy"
        >
          {number}
        </Text>
      </Box>
      <Box>
        <Text
          textAlign="center"
          marginTop="-8px"
          className="animation-heart"
          fontSize="sm"
          color="mainColorTextLight"
        >
          {text}
        </Text>
      </Box>
    </Box>
  );
}

CountItem.propTypes = {
  number: number.isRequired,
  text: string.isRequired,
};

export default React.memo(CountItem);
