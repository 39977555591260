import { css } from '@emotion/react';

import { BG_SECONDARY_TRANSPARENT } from '@/constants/colors';
import { BG_COVER } from '@/constants/assets';

export const styCoverV4 = css`
  min-height: 90vh;
  background-size: cover;
  background-image: url(${BG_COVER});
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 0 2000px ${BG_SECONDARY_TRANSPARENT};
`;
