import React from 'react';
import { FaGlassCheers } from 'react-icons/fa';
import { string } from 'prop-types';
import { Box, Center, Text, Heading } from '@chakra-ui/react';

import CountDown from '@components/Common/CountDown';
import WithAnimation from '@components/Common/WithAnimation';
import IconWeddingRing from '@components/Common/Icons/WeddingRing';

import useInvitation from '@/hooks/useInvitation';
//import useShiftTime from '@/hooks/useShiftTime';

import WeddingLocation from './WeddingLocation';
import WeddingDetail from './WeddingDetail';
import txtWording from './locales';

import { BG_PATTERN, BG_WEDDING_DETAIL } from '@/constants/assets';
import {
  WEDDING_AKAD_TIME,
  WEDDING_AKAD_FULLDATE,
  WEDDING_AKAD_LOC_NAME,
  WEDDING_AKAD_LOC_ROAD,
  WEDDING_RESEPSI_LOC_NAME,
  WEDDING_RESEPSI_LOC_ROAD,
  WEDDING_RESEPSI_FULLDATE,
} from '@/constants';
import useLang from '@hooks/useLang';

/**
 * function to render WeddingSectionV4 component
 * @returns {JSX.Element}
 */
function WeddingSectionV4({ color, ...rest }) {
  const isInvitation = useInvitation();
  const lang = useLang()
  //const time = useShiftTime();

  return (
    <Box backgroundImage={`url(${BG_PATTERN})`} padding="32px 16px" {...rest}>
      <Box>
        <Box boxShadow="2xl" backgroundColor="mainColor" borderRadius="2xl" padding="36px 32px">
          <WithAnimation>
            <Center>
              <Heading
                fontSize="3xl"
                color="mainColorTextLight"
                fontWeight="bold"
                align="center"
                fontFamily="fantasy"
              >
                {txtWording.title[lang]}
              </Heading>
            </Center>
          </WithAnimation>
          <WithAnimation>
            <Text color="mainColorTextLight" align="center">
              {txtWording.subTitle[lang]}
            </Text>
          </WithAnimation>
          <WithAnimation>
            <Center margin="12px 0">
              <CountDown lang={[lang]} />
            </Center>
          </WithAnimation>
          <Box
            backgroundImage={`url(${BG_WEDDING_DETAIL})`}
            backgroundSize="cover"
            backgroundPosition="80% 20%"
            borderRadius="16px"
            padding="16px"
            marginTop="16px"
            boxShadow="inset 0 0 0 2000px rgb(195	207	187 / 59%)"
          >
            <WithAnimation>
              <Box>
                <WeddingDetail
                  color={color}
                  title={txtWording.akadName[lang]}
                  titleProps={{ color }}
                  Icon={<IconWeddingRing height="50px" width="50px" color={color} />}
                  subTitle={
                    <>
                      {!isInvitation &&
                        <>
                          <Text margin="6px 0 6px">{WEDDING_AKAD_TIME}</Text>
                          <Text marginBottom="6px">{WEDDING_AKAD_FULLDATE[lang]}</Text>
                        </>
                      }
                      {isInvitation &&
                        <>
                          <span>{WEDDING_AKAD_TIME}</span>
                          <br />
                          <span>{WEDDING_AKAD_FULLDATE[lang]}</span>                          
                          <br /><br />
                          <span>{WEDDING_AKAD_LOC_NAME}</span>
                          <br />
                          <span>{WEDDING_AKAD_LOC_ROAD}</span>
                        </>
                      }
                    </>
                  }
                />
              </Box>
            </WithAnimation>
            {isInvitation && (
              <WithAnimation>
                <Box>
                  <WeddingDetail
                    color={color}
                    title={txtWording.receptionName[lang]}
                    titleProps={{ color }}
                    Icon={<FaGlassCheers size="40px" color={color} />}
                    subTitle={
                      <>
                        <span>Kombucha Hour 16.00 WIB</span>
                        <br />
                        <span>{lang == 'id' ? `Dinner Reception 17.00 WIB – Selesai` : `Dinner Reception 17.00 WIB – Finish`}</span>
                        <br />
                        <span>{WEDDING_RESEPSI_FULLDATE[lang]}</span>
                        <br />
                        <span>Dress code: 70s BLACK / GREY</span>
                        <br /><br />
                        <span>{WEDDING_RESEPSI_LOC_NAME}</span>
                        <br />
                        <span>{WEDDING_RESEPSI_LOC_ROAD}</span>
                      </>
                    }
                  />
                </Box>
              </WithAnimation>
            )}
            <Box>
              <WithAnimation>
                <WeddingLocation
                  color={color}
                  withIcon={false}
                  withRoad={false}
                  withLocation={false}
                />
              </WithAnimation>
            </Box>
            {isInvitation &&
            <WithAnimation>
              <Center>
                  <Text textAlign='center' fontSize='sm' color='white' marginBottom='12px'>
                   {lang == 'id' ? `*) Silakan berpakaian dengan nyaman dan pilih alas kaki yang sesuai untuk pernikahan outdoor kami di halaman. Jangan ragu untuk memakai kacamata hitam.` :
                   `*) Please dress comfortably and choose appropriate footwear for our outdoor wedding on the lawn. Feel free to wear sunglasses.`
                   }
                  </Text>
              </Center>
            </WithAnimation>
            }
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

WeddingSectionV4.propTypes = {
  color: string,
};

WeddingSectionV4.defaultProps = {
  color: 'white',
};

export default React.memo(WeddingSectionV4);
