import React from 'react';
import { string, object } from 'prop-types';
import { Box, Center, Heading, Text } from '@chakra-ui/react';

import InstagramButton from '@components/Common/InstagramButton';
import Image from '@components/Common/LazyImage';

/**
 * Function to render CoupleDetailCard Info
 * @return {JSX.Element}
 */
function CoupleDetailCard(props) {
  const {
    name,
    fullName,
    parentInfo,
    instagramId,
    imgSrc,
    fullNameProps,
    parentInfoProps,
    instagramIdProps,
  } = props;
  return (
    <Box>
      <Center>{imgSrc && <Image src={imgSrc} maxWidth="120px" borderRadius="8px" />}</Center>
      <Center>
        <Heading fontSize="2xl" color="#d09d2d">
          {name}
        </Heading>
      </Center>
      <Center>
        <Text
          fontFamily="heading"
          fontSize="2xl"
          align="center"
          fontWeight="bold"
          color="mainColorTextLight"
          {...fullNameProps}
        >
          {fullName}
        </Text>
      </Center>
      <Center>
        <Text textAlign="center" fontSize="sm" color="mainColorTextLight" {...parentInfoProps}>
          <span color="mainColorTextLight" dangerouslySetInnerHTML={{ __html: parentInfo }} />
        </Text>
      </Center>
      {instagramId && (
        <Center p={2}>
          <InstagramButton id={instagramId} {...instagramIdProps} />
        </Center>
      )}
    </Box>
  );
}

CoupleDetailCard.propTypes = {
  fullName: string.isRequired,
  fullNameProps: object,
  imgSrc: string,
  instagramId: string,
  instagramIdProps: object,
  name: string.isRequired,
  parentInfo: string.isRequired,
  parentInfoProps: object,
};

CoupleDetailCard.defaultProps = {
  instagramId: '',
  imgSrc: '',
  instagramIdProps: {},
  fullNameProps: {},
  parentInfoProps: {},
};

export default CoupleDetailCard;
