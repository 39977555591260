import React from 'react';
import { string } from 'prop-types';
import { Button } from '@chakra-ui/react';
import { FaInstagram } from 'react-icons/fa';

function InstagramButton({ id, ...rest }) {
  const handleRedirect = () => {
    if (!id) return '';

    window.open(`https://instagram.com/${id}`, '_blank');
  };

  return (
    <Button
      leftIcon={<FaInstagram />}
      variant="solid"
      colorScheme="button.secondaryDark"
      size="xs"
      borderRadius="8px"
      padding="8px 16px"
      onClick={handleRedirect}
      {...rest}
    >
      {id}
    </Button>
  );
}

InstagramButton.propTypes = {
  id: string,
};

InstagramButton.defaultProps = {
  id: '',
};

export default InstagramButton;
