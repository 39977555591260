import React, { useMemo } from 'react';
import { scroller } from 'react-scroll';
import { GiPeaceDove } from 'react-icons/gi';
import {
  Box,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  // Image,
  Text,
  Divider,
  DrawerFooter,
  //hooks
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { MdClose, MdMenu } from 'react-icons/md';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

import useInvitation from '@/hooks/useInvitation';
import useIsDesktopView from '@/hooks/useIsDesktopView';
import useLang from '@/hooks/useLang';
import { THE_BRIDE } from '@/constants';
import { BG_NAVIGATION, TEXT_PRIMARY } from '@/constants/colors';

import useMenudata from './hooks/useMenuData';
import txt from './locales';

function Navigation() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isDesktopView } = useIsDesktopView();
  const toast = useToast();
  const lang = useLang();
  const menuData = useMenudata();
  const btnRef = React.useRef();
  const isInvitation = useInvitation();

  const filteredMenuData = useMemo(
    () =>
      menuData.filter((i) => {
        if (isInvitation) return true;
        if (!i.invitationOnly) return true;
        return false;
      }),
    [],
  );

  const onClickMenu = (ELEMENT_CONTENT_ID, offset) => {
    trackCustomEvent({
      category: 'navigation',
      action: 'click content navigation',
      label: ELEMENT_CONTENT_ID,
    });

    try {
      onClose();
      // const element = document.getElementById(ELEMENT_CONTENT_ID).offsetTop;
      scroller.scrollTo(ELEMENT_CONTENT_ID, {
        duration: 1500,
        delay: 100,
        smooth: true,
        offset,
      });
    } catch {
      toast({
        title: 'Oops!',
        description: txt.menuNotFound[lang],
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const onClickIconMenu = () => {
    trackCustomEvent({
      category: 'navigation',
      action: 'click icon navigation',
      label: isOpen ? 'close' : 'open',
    });

    isOpen ? onClose() : onOpen();
  };

  return (
    <>
      <IconButton
        colorScheme="blackAlpha"
        ref={btnRef}
        borderRadius="50%"
        position="fixed"
        zIndex="999"
        bottom="12px"
        left="12px"
        size="sm"
        boxShadow="xl"
        bgColor={BG_NAVIGATION}
        onClick={onClickIconMenu}
        icon={isOpen ? <MdClose color="white" /> : <MdMenu color="white" />}
      />
      <Drawer size="xs" placement="right" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent
          maxWidth={isDesktopView ? '500px' : '75%'}
          bgColor="bgPrimary"
          padding="24px 16px"
        >
          <DrawerBody textAlign="right">
            {/* put here if any logo */}
            {/* <Image src={IMG_LOGO} width="100px" position="absolute" right="32px" /> */}
            <Box position="absolute" right="32px">
              <GiPeaceDove color={TEXT_PRIMARY} size="60px" />
            </Box>
            <Box marginTop="100px">
              {filteredMenuData.map((i) => {
                return (
                  <div key={i.value}>
                    <Text
                      fontSize="sm"
                      marginTop="16px"
                      textTransform="uppercase"
                      color="mainColorText"
                      letterSpacing="3px"
                      onClick={() => onClickMenu(i.value, i.offset)}
                    >
                      {i.name}
                    </Text>
                    <Divider width="100%" marginTop="8px" borderColor="blackAlpha.300" />
                  </div>
                );
              })}
            </Box>
          </DrawerBody>
          <DrawerFooter>
            <Box textAlign="center" fontSize="xs">
              <Text color="mainColorText">Created with Love by Invitato</Text>
              <Text color="mainColorText">{`${new Date().getFullYear()} ${THE_BRIDE}. All Rights Reserved`}</Text>
            </Box>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default Navigation;
