import React from 'react';
import { Box, Text, Button, Center, useDisclosure, Image } from '@chakra-ui/react';

import ImgLogo from '@/assets/icons/bouquet.svg';
import WithAnimation from '@/components/Common/WithAnimation';

import Content from './Content';
import txtWording from './locales';

import { styles } from './styles';
import { BG_START_JOURNEY } from '@/constants/assets';
import { DEFAULT_LANGUAGE as lang } from '@/constants/features-flag';

function LoveStory() {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Box bgColor="bgPrimary">
      {/* Heading */}
      <WithAnimation></WithAnimation>
      {/* Image Cover */}
      <Box padding="16px 32px" paddingBottom="42px">
        <Box css={styles}>
          <WithAnimation>
            <Image src={BG_START_JOURNEY} width="100%" boxShadow="2xl" />
          </WithAnimation>
        </Box>
        <WithAnimation>
          <Box padding="0 8px">
            <Box bgColor="mainColor" padding="16px" borderRadius="0 0 24px 24px" marginTop="-24px">
              <Box height="24px" />
              {/* use for wedding logo */}
              {false && (
                <Center>
                  <Image src={ImgLogo} width="100px" />
                </Center>
              )}
              <WithAnimation>
                <Text padding="8px" color="white" fontSize="sm" textAlign="center">
                  <span dangerouslySetInnerHTML={{ __html: txtWording.desc[lang] }} />
                </Text>
              </WithAnimation>
              <WithAnimation>
                <Center margin="16px 0">
                  <Button
                    colorScheme="blackAlpha"
                    bgColor="white"
                    color="mainColor"
                    fontFamily="body"
                    size="sm"
                    fontWeight="normal"
                    onClick={() => onOpen()}
                  >
                    {txtWording.textButton[lang]}
                  </Button>
                </Center>
              </WithAnimation>
            </Box>
          </Box>
        </WithAnimation>
      </Box>

      <Content onClose={onClose} isOpen={isOpen} />
    </Box>
  );
}

export default LoveStory;
