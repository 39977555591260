import React, { useState } from 'react';
import { func, bool } from 'prop-types';
import { IoIosArrowDroprightCircle, IoMdInformationCircle } from 'react-icons/io';
import {
  Box,
  Text,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerHeader,
  DrawerCloseButton,
  List,
  ListItem,
  ListIcon,
  // form
  FormControl,
  FormLabel,
  Input,
  Button,
  Image,
  useToast,
} from '@chakra-ui/react';

import useMutation from '@/hooks/useMutation';
import getTimeNow from '@invitato/helpers/dist/getTimeNow';

import ImgDresscode from '@/images/dresscode.jpg';
import { API_HOSTNAME_RSVP } from '@/constants';

/**
 * Function to render additional information
 * @param {props} props
 * @returns
 */
function AdditionalInformation({ isOpen, onClose }) {
  const [name, setName] = useState('');
  const toast = useToast();
  const [onSubmit, loading] = useMutation(
    API_HOSTNAME_RSVP,
    'ceremony_attended',
    'insert_ceremony',
  );

  const setNameValue = (e) => {
    setName(e.target.value);
  };

  const hadleSubmitData = async () => {
    const result = await onSubmit(`name=${name}&time=${getTimeNow()}`);

    if (result.success) {
      toast({
        title: 'RSVP Submitted!',
        description: 'Your response already submitted. Thank you!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setName('');
      onClose();
    } else {
      toast({
        title: 'Oops!',
        description: 'Something went wrong. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const renderInfo = (title, description) => {
    return (
      <Box padding="16px 0">
        <Text fontSize="xl">{title}</Text>
        <Text fontSize="sm" color="mainColorText">
          {description}
        </Text>
      </Box>
    );
  };

  return (
    <Drawer isFullHeight placement="bottom" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton marginTop="12px" />
        <DrawerHeader
          borderBottomWidth="1px"
          fontSize="2xl"
          fontFamily="fantasy"
          color="mainColorText"
        >
          Bali Guest Information
        </DrawerHeader>
        <DrawerBody>
          {renderInfo(
            'Hotel address',
            <>
              Swiss-Belresort Pecatu <br />
              Jl. Pecatu Indah Raya Blok G2, <br />
              Pecatu Indah Resort, Bali, Indonesia <br />
              <Box display="flex" alignItems="center">
                <IoMdInformationCircle size="14px" style={{ marginRight: '4px' }} />
                Check in: Sat, 25 Sep 2021 - 12.00 WITA
              </Box>
              <Box display="flex" alignItems="center">
                <IoMdInformationCircle size="14px" style={{ marginRight: '4px' }} />
                Check out: Sun, 26 Sep 2021
              </Box>
              (We will arrange transportation to the venue and back to the hotel.)
            </>,
          )}
          {renderInfo(
            'Dresscode',
            <>
              Formal but Comfy. Feel free to adjust with our colour palette.
              <Image src={ImgDresscode} borderRadius="16px" margin="8px 0" />
              We are having a garden party and expect a high intensity of sunlight during the
              ceremony
            </>,
          )}
          {renderInfo(
            'Schedule/Rundown',
            <List>
              <ListItem display="flex" alignItems="center">
                <ListIcon as={IoIosArrowDroprightCircle} color="green.500" />
                15.00 - Chinese tea ceremony (family only)
              </ListItem>
              <ListItem display="flex" alignItems="center">
                <ListIcon as={IoIosArrowDroprightCircle} color="green.500" />
                15.30 - Holy Matrimony
              </ListItem>
              <ListItem display="flex" alignItems="center">
                <ListIcon as={IoIosArrowDroprightCircle} color="green.500" />
                16.30 - Sunset Cocktail
              </ListItem>
              <ListItem display="flex" alignItems="center">
                <ListIcon as={IoIosArrowDroprightCircle} color="green.500" />
                17.30 - Reception
              </ListItem>
            </List>,
          )}
          {renderInfo(
            'Information about ceremony',
            <>
              Apologies ahead, due to Covid situation the Holy Matrimony will be held with a limited
              capacity.
            </>,
          )}
          <Box paddingBottom="32px">
            <FormControl margin="8px 0">
              <FormLabel fontSize="sm" color="mainColorText" marginBottom="4px">
                Please fill in your name if you plan to attend the ceremony.
              </FormLabel>
              <Input
                borderRadius="md"
                focusBorderColor="theme.bgSecondary"
                isRequired
                placeholder="Your Name"
                size="sm"
                type="text"
                onChange={setNameValue}
                value={name}
              />
            </FormControl>
            <Button
              isLoading={loading}
              size="sm"
              fontWeight="normal"
              colorScheme="orange"
              onClick={hadleSubmitData}
            >{`Send Confirmation`}</Button>
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

AdditionalInformation.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
};

export default AdditionalInformation;
